/**
 * Translation layer for Dataset frontend and backend
 */

import { FormikValues } from 'formik';
import {
  Dataset,
  Member,
  User,
  HiveCondition,
  HiveConditionObject,
  RefreshHistoryRequest,
} from '../types';
import { DataFeedConfig, Entity, Interval, DataModel, FileFormat } from './proto/dataset_config';
import {
  DATA_TYPES,
  REFRESH_FREQUENCY_OPTIONS,
  SOURCE_TYPE_S3,
  SOURCE_TYPE_HIVE,
  SOURCE_OPTIONS,
  DATA_INDEX_COLUMNS,
  DEFAULT_FIELD_MAPPINGS,
  S3_FILE_FORMATS,
  S3_DEFAULT_DELIMITER,
  DEFAULT_DATASET,
  DEFAULT_DASG_CONFIG,
  SOURCE_OPTION_S3,
  SOURCE_OPTION_HIVE,
} from '../constants/add-data-constants';

/**
 * Converts FormikValues of AddDataSummary into JSON Payload
 * for the create dataset endpoint (POST: `/datasets`)
 */
export function formToDataset(
  formData: FormikValues,
  datasetValues: Dataset = DEFAULT_DATASET
): Dataset {
  return {
    ...datasetValues,
    name: formData.dataName,
    description: formData.dataDescription,
    datatype: formData.dataType.id,
    data_provider: formData.dataProvider.id,
    ingest_date: new Date().toISOString().slice(0, 10), // Today's date
  };
}

/**
 * Converts FormikValues of AddDataSummary into JSON payload
 * for the update dataset members endpoint (PUT `/datasets/{id}/members`)
 * @param formData
 */
export function formToDatasetMembers(formData: FormikValues): Member[] {
  return [
    ...formData.dataAdmins.map((admin: User) => ({
      user_id: admin.user_id,
      email: admin.email,
      is_admin: true,
    })),
    ...formData.dataTeamMembers.map((member: User) => ({
      user_id: member.user_id,
      email: member.email,
      is_admin: false,
    })),
  ];
}

/**
 * Converts FormikValues of AddDataSummary into JSON payload
 * for the create dataset config endpoint of the attribute
 * statement generation service (POST `/datasets/{id}/members`)
 */
export function formToDatasetConfig(
  formData: FormikValues,
  originalConfig: DataFeedConfig
): DataFeedConfig {
  const coeff = 1000 * 60 * 5;
  const now = new Date(); //or use any other date
  const refreshStartDate = new Date(Math.ceil(now.getTime() / coeff) * coeff)
    .toISOString()
    .slice(0, 16);

  // Make refreshEndDate blank if the expiration fields are empty/null
  const refreshEndDate =
    formData.expirationYear && formData.expirationMonth
      ? new Date(formData.expirationYear.value, formData.expirationMonth.value, 1)
          .toISOString()
          .slice(0, 10)
      : '';

  const castDateFieldsToArray = (field: string) => {
    const fieldValue = formData.fieldMapping[field];
    return fieldValue && field === 'dateFields' ? Array(fieldValue) : fieldValue;
  };

  const indexedFields = [...formData.indexedFields].reduce(
    (acc, field) => ({
      ...acc,
      [field]: castDateFieldsToArray(field),
    }),
    {}
  );

  const earliestDate =
    formData.dataAvailableYear?.value != undefined &&
    formData.dataAvailableMonth?.value != undefined
      ? new Date(formData.dataAvailableYear.value, formData.dataAvailableMonth.value, 1)
          .toISOString()
          .slice(0, 10)
      : '';

  const config: DataFeedConfig = {
    ...DEFAULT_DASG_CONFIG,
    ...originalConfig,
    name: formData.dataName,
    models: [
      {
        dataType: 'private_tile',
        fileSource: getFileConfigFromFormData(formData),
        hiveSource: getHiveConfigFromFormData(formData),
        hllConfig: {
          hllModelName: 'private_tile',
        },
        fieldMapping: {
          ...fieldMappings,
          ...indexedFields,
        },
        statsConfig: originalConfig?.models[0]?.statsConfig,
      },
    ],
    entities:
      formData.indexedFields.has('patientIdentifierField') &&
      formData.indexedFields.has('providerIdentifierField')
        ? [Entity.PATIENT, Entity.PROVIDER]
        : formData.indexedFields.has('patientIdentifierField')
        ? [Entity.PATIENT]
        : [Entity.PROVIDER],
    earliestDate: earliestDate,
    refreshConfig:
      formData.refreshFrequency.value === 'NONE'
        ? undefined
        : {
            rate: formData.refreshFrequency.value,
            startDate: refreshStartDate,
            endDate: refreshEndDate,
          },
  };

  return config;
}

const fieldMappings = {
  patientIdentifierField: '',
  providerIdentifierField: '',
  dateFields: [],
  dateWindowStartField: '',
  dateWindowEndField: '',
  ageField: '',
  yearOfBirthField: '',
  genderField: '',
  bmiField: '',
  bmiMeasurementField: '',
  patientStateField: '',
  patientZip3Field: '',
  providerStateField: '',
  providerZip3Field: '',
  providerSpecialtyField: '',
  procedureField: '',
  labField: '',
  drugField: '',
  diagnosisField: '',
  diagnosisQualField: '',
  genericMedicationField: '',
  datafeedIdField: '',
};

/**
 * Converts Attr Stmt dataset config to Formik form values
 */
export function datasetConfigToForm(config: DataFeedConfig, dataset: Dataset) {
  const earliestDate = config.earliestDate ? getUTCDateFromDateString(config.earliestDate) : null;
  const expirationDate =
    config.refreshConfig && config.refreshConfig.endDate
      ? getUTCDateFromDateString(config.refreshConfig.endDate)
      : null;

  const {
    dataSourceType,
    dataSourceS3,
    dataSourceS3FileFormat,
    dataSourceS3Delimiter,
    dataSourceHive,
    dataSourceHiveConditions,
  } = getFormDataFromSourceConfig(config);

  const castDateFieldsToString = (field: string, name: string | string[] | undefined) => {
    return field === 'dateFields' && name?.length === 1 ? name[0] : name;
  };

  const indexedFields = Object.entries(config.models[0]?.fieldMapping || {})
    .filter(([field, name]) => name.length > 0)
    .reduce(
      (acc, [field, name]) => ({
        ...acc,
        [field]: castDateFieldsToString(field, name),
      }),
      {}
    );

  const formConfig = {
    // If fileSource is defined, set to S3. Otherwise, set to Hive
    dataSourceType: dataSourceType,
    /* S3-Specific fields */
    dataSourceS3: dataSourceS3,
    dataSourceS3FileFormat: dataSourceS3FileFormat,
    dataSourceS3Delimiter: dataSourceS3Delimiter,
    /* Hive-Specific fields */
    dataSourceHive: dataSourceHive,
    dataSourceHiveConditions: dataSourceHiveConditions,

    dataName: dataset.name,
    dataDescription: dataset.description,
    dataProvider: { id: dataset.data_provider, name: '' },
    dataTeamMembers: dataset.members?.filter((m) => !m.is_admin),
    dataAdmins: dataset.members?.filter((m) => m.is_admin),
    dataDestination: { value: 'HVM', label: 'HealthVerity marketplace (private data)' },
    dataType: {
      id: dataset.datatype,
      name: DATA_TYPES.find(({ id }) => id == dataset.datatype)?.name,
    },
    dataAvailableMonth: earliestDate
      ? {
          value: earliestDate.getMonth(),
          label: earliestDate.toLocaleString('default', { month: 'long' }),
        }
      : null,
    dataAvailableYear: earliestDate
      ? {
          value: earliestDate.getFullYear(),
          label: earliestDate.getFullYear(),
        }
      : null,
    expirationMonth: expirationDate
      ? {
          value: expirationDate.getMonth(),
          label: expirationDate.toLocaleString('default', { month: 'long' }),
        }
      : null,
    expirationYear: expirationDate
      ? {
          value: expirationDate.getFullYear(),
          label: expirationDate.getFullYear(),
        }
      : null,
    fieldMapping: {
      ...DEFAULT_FIELD_MAPPINGS,
      ...indexedFields,
    },
    indexedFields: new Set(Object.keys(indexedFields)),
    refreshFrequency: config.refreshConfig
      ? {
          value: config.refreshConfig.rate,
          label: REFRESH_FREQUENCY_OPTIONS.find(({ value }) => value == config!.refreshConfig!.rate)
            ?.label,
        }
      : { value: 'NONE', label: 'None' },
  };
  return formConfig;
}

/**
 * Converts FormikValues into fileSource sub-object under DataFeedConfig
 * Will return the fileSource object if chosen method is SOURCE_TYPE_S3
 * or undefined
 */
export function getFileConfigFromFormData(formData: FormikValues) {
  const isDelimitedType =
    formData?.dataSourceS3FileFormat?.value === FileFormat.DSV_QUOTE ||
    formData?.dataSourceS3FileFormat?.value === FileFormat.DSV_NOQUOTE;
  const fileSourceConfig =
    formData.dataSourceType.value == SOURCE_TYPE_S3
      ? {
          path: formData.dataSourceS3.slice(0, 2) + 'a' + formData.dataSourceS3.slice(2),
          delimiter:
            formData?.dataSourceS3Delimiter && isDelimitedType
              ? formData.dataSourceS3Delimiter
              : '',
          fileFormat: formData?.dataSourceS3FileFormat
            ? formData.dataSourceS3FileFormat.value
            : FileFormat.JSON,
        }
      : undefined;
  return fileSourceConfig;
}

/**
 * Converts FormikValues into hiveSource sub-object under DataFeedConfig
 * Will return the hiveSource object if chosen method is SOURCE_TYPE_HIVE
 * or undefined
 */
export function getHiveConfigFromFormData(formData: FormikValues) {
  if (formData.dataSourceType.value != SOURCE_TYPE_HIVE) {
    return undefined;
  }

  // Note: this is converting our formData data object structured from
  // [ {conditionKey: "key1", conditionValue: "val1"}, {conditionKey: "key2", conditionValue: "val2"}, ...]
  // to a single object of key/value pairs like: {key1: val1, key2: value2}
  const hiveSourcePartitionConditions: HiveConditionObject =
    formData.dataSourceHiveConditions.reduce(
      (obj: HiveConditionObject, { conditionKey, conditionValue }: HiveCondition) => {
        if (conditionKey.length > 0 && conditionValue.length > 0) {
          obj[conditionKey] = conditionValue;
        }
        return obj;
      },
      {}
    );

  const hiveSourceConfig = {
    schema: formData.dataSourceHive.includes('.')
      ? formData.dataSourceHive.split('.')[0]
      : 'default',
    tableName: formData.dataSourceHive.includes('.')
      ? formData.dataSourceHive.split('.').slice(1).join('.')
      : formData.dataSourceHive,
    partitionCondition: hiveSourcePartitionConditions,
  };
  return hiveSourceConfig;
}

/**
 * Converts DataFeedConfig to the necessary source-type values needed to define FormikValues
 * Will analyze DataFeedConfig obj and return the dataSource values
 */
export function getFormDataFromSourceConfig(config: DataFeedConfig) {
  const fileSourceObj = config.models[0].fileSource ? config.models[0].fileSource : null;
  const hiveSourceObj = config.models[0].hiveSource ? config.models[0].hiveSource : null;

  const dataSourceType = fileSourceObj ? SOURCE_OPTION_S3 : SOURCE_OPTION_HIVE;
  const dataSourceS3 = fileSourceObj
    ? fileSourceObj.path.slice(0, 2) + fileSourceObj.path.slice(3)
    : 's3://';
  const dataSourceS3FileFormat = fileSourceObj
    ? S3_FILE_FORMATS.filter((fileFormat) => fileFormat.value === fileSourceObj.fileFormat)[0]
    : { value: FileFormat.JSON, label: 'JSON' };
  const dataSourceS3Delimiter =
    fileSourceObj && fileSourceObj.delimiter ? fileSourceObj.delimiter : S3_DEFAULT_DELIMITER;

  const dataSourceHive = hiveSourceObj ? hiveSourceObj.schema + '.' + hiveSourceObj.tableName : '';

  // Note: this is converting our config data from a single object of key/value pairs like: {key1: val1, key2: value2}
  // to an array-based object like:
  // [ {conditionKey: "key1", conditionValue: "val1"}, {conditionKey: "key2", conditionValue: "val2"}, ...]
  const dataSourceHiveConditions: Array<HiveCondition> =
    hiveSourceObj && Object.keys(hiveSourceObj.partitionCondition).length > 0
      ? Object.keys(hiveSourceObj.partitionCondition).map((key: string) => ({
          conditionKey: key,
          conditionValue: hiveSourceObj.partitionCondition[key],
        }))
      : [{ conditionKey: '', conditionValue: '' }];

  return {
    dataSourceType,
    dataSourceS3,
    dataSourceS3FileFormat,
    dataSourceS3Delimiter,
    dataSourceHive,
    dataSourceHiveConditions,
  };
}

/*
 * Filters out raw array of RefreshHistoryRequest from Sutton getDataFeedRefreshHistory endpoint
 * and returns only successful, HLL-type requests
 */
export function filterFeedHistoryRequests(
  unfilteredFeedHistoryRequests: RefreshHistoryRequest[]
): RefreshHistoryRequest[] {
  return unfilteredFeedHistoryRequests.filter(
    (request: RefreshHistoryRequest) =>
      request.was_success === true && request.request_type === 'hlls'
  );
}

/*
 * Given a date string (expected format is YYYY-MM-DD)
 * Outputs a new Date object that preserves year & month
 * Without backwards drifts caused by time zone issues
 */
export function getUTCDateFromDateString(dateString: string): Date {
  const dateWithTimezone = new Date(dateString);
  const utcDate = new Date(
    dateWithTimezone.getUTCFullYear(),
    dateWithTimezone.getUTCMonth(),
    dateWithTimezone.getUTCDate()
  );
  return utcDate;
}
