import React, { useEffect, useState } from 'react';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.scss';

import DatasetTable from './Table/DatasetTable';
import Navbar from './Navbar/Navbar';
import { DatasetFormCreate, DatasetFormUpdate } from './Form/DatasetForm';

import { Backend, AttributeStatementBackend, Auth0Authenticator } from '../backend';

import jwt_decode from 'jwt-decode';

const BACKEND_BASE_URL = process.env['REACT_APP_BACKEND_BASE_URL'] || 'http://localhost:8001/local';
const ATTR_STMT_BACKEND_BASE_URL =
  process.env['REACT_APP_ATTR_STMT_BACKEND_BASE_URL'] ||
  'https://api.us-east-1.discovery-services-0.staging.comm.platform.healthverity.org/dasg';

export const REQUIRED_PERMS = [
  'dataset_mgmt:read:dataset',
  'dataset_mgmt:write:dataset',
  'dataset_mgmt:read:organizations',
  'dataset_mgmt:read:users',
];

export const ATTR_STMT_REQUIRED_PERMS = [
  'data_attr_stmt_gen:read:dataset',
  'data_attr_stmt_gen:write:dataset',
  'data_attr_stmt_gen:read:request',
  'data_attr_stmt_gen:write:request',
];

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
    user,
  } = useAuth0();

  // The isAuthorized variable is set post-authentication and used to verify
  // whether a user is authorized to use the application. When the value is undefined,
  // authorization is still in-progress
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(undefined);
  const isAuthorizing = isAuthorized === undefined;

  const handleLogout = () => logout({ returnTo: window.location.origin });

  const scope = REQUIRED_PERMS.join(' ');
  const backend: Backend = new Backend({
    baseUrl: BACKEND_BASE_URL,
    authenticator: new Auth0Authenticator(() => getAccessTokenSilently({ scope })),
  });

  const attrStmtScope = ATTR_STMT_REQUIRED_PERMS.join(' ');
  const attrStmtBackend: AttributeStatementBackend = new AttributeStatementBackend({
    baseUrl: ATTR_STMT_BACKEND_BASE_URL,
    authenticator: new Auth0Authenticator(() => getAccessTokenSilently({ attrStmtScope })),
  });

  // If the user is not logged in, redirect them to the login screen
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      loginWithRedirect();
    }
  }, [loginWithRedirect, isAuthenticated, isLoading, error]);

  // Once the user is authenticated, check their access token
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({ scope }).then((token) => {
        const parsed = jwt_decode(token) as { scope: string };
        const perms = parsed.scope.split(' ');
        setIsAuthorized(REQUIRED_PERMS.every((s) => perms.indexOf(s) > -1));
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  function getContents() {
    // If there is an authentication error, show that error message
    if (error) {
      return (
        <div className="auth-message">
          <span>Oops... {error.message}</span>
          <button onClick={handleLogout}>Logout</button>
        </div>
      );
    }

    // If the user is not authorized, show an authorization failure message
    if (isAuthorized === false) {
      return (
        <div className="auth-message">
          <p>
            Uh-oh. It looks as if your user account does not have the required permissions to view
            this page.
          </p>
          <p>Please contact support@healthverity.com if you need to gain access.</p>
        </div>
      );
    }

    // If the user is authorized, show the application
    if (isAuthorized === true) {
      return (
        <Switch>
          <Route exact path="/">
            <DatasetTable backend={backend} attrStmtBackend={attrStmtBackend} />
          </Route>
          <Route exact path="/add-dataset">
            <DatasetFormCreate backend={backend} attrStmtBackend={attrStmtBackend} user={user} />
          </Route>
          <Route path="/edit-dataset/:id">
            <DatasetFormUpdate backend={backend} attrStmtBackend={attrStmtBackend} user={user} />
          </Route>
        </Switch>
      );
    }

    // If there's no explicit error and authorization is unknown, then
    // auth is still loading.
    return <div title="loading-spinner" className="loading"></div>;
  }

  return (
    <Router>
      <div className={'app-container'}>
        <Navbar onLogout={handleLogout} loggedIn={isAuthenticated} />
        <div id={'contents'}>{getContents()}</div>
      </div>
    </Router>
  );
}

export default App;
