import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import isEqual from 'lodash.isequal';

import './DatasetForm.scss';
import checkmark from '../../images/checkmark-white.svg';

import AutoscanningDataModal from '../Modal/AutoscanningDataModal';
import IdentifiableInfoModal from '../Modal/IdentifiableInfoModal';
import ConfigureDataFlow from './ConfigureDataFlow';
import ProvideDataInformation from './ProvideDataInformation';
import SelectDataPermission from './SelectDataPermissions';
import NewDataSummary from './NewDataSummary';
import ProcessingNotification from './ProcessingNotification';
import {
  CONFIGURE_DATA_FLOW_FORM_FIELDS,
  DESTINATION_OPTIONS,
  PROVIDE_DATA_INFORMATION_FORM_FIELDS,
  S3_PATH_VALIDATION_REGEX,
  HIVE_VALIDATION_REGEX,
  SELECT_DATA_PERMISSIONS_FORM_FIELDS,
  SOURCE_TYPE_S3,
  SOURCE_TYPE_HIVE,
  DEFAULT_FIELD_MAPPINGS,
  S3_DEFAULT_DELIMITER,
  DEFAULT_DATASET,
  DEFAULT_DASG_CONFIG,
  HIVE_DEFAULT_VALUE,
  DATA_TYPES,
} from '../../constants/add-data-constants';
import { Backend, AttributeStatementBackend } from '../../backend';
import { DataFeedConfig, FieldMapping, FileFormat } from '../../backend/proto/dataset_config';
import {
  formToDataset,
  formToDatasetMembers,
  formToDatasetConfig,
  datasetConfigToForm,
} from '../../backend/formatters';
import { User, DataProvider, HiveCondition, FieldMappingType, Dataset } from '../../types';
import performFieldMappingOnScannedFields, {
  ScannedFieldMappingResultProps,
  scannedFieldMappingEmptyResult,
} from '../../utils/scannedFieldMapping';
import NotEditableModal from '../Modal/NotEditableModal';

type DatasetFormCreateProps = {
  backend: Backend;
  attrStmtBackend: AttributeStatementBackend;
  user: User;
};

let dataAdmins: User[] = [];

const INITIAL_VALUES = {
  dataSourceType: { label: 'Select data from warehouse table', value: 'HIVE' },
  dataSourceS3: 's3://',
  dataSourceS3FileFormat: { value: FileFormat.JSON, label: 'JSON' },
  dataSourceS3Delimiter: S3_DEFAULT_DELIMITER,
  dataSourceHive: HIVE_DEFAULT_VALUE,
  dataSourceHiveConditions: [{ conditionKey: '', conditionValue: '' }],
  dataDestination: DESTINATION_OPTIONS[0],
  expirationMonth: null,
  expirationYear: null,
  refreshFrequency: null,
  fieldMapping: DEFAULT_FIELD_MAPPINGS,
  indexedFields: new Set(),
  dataProvider: null,
  dataType: null,
  dataName: '',
  dataDescription: '',
  dataAvailableMonth: null,
  dataAvailableYear: null,
  dataAdmins: dataAdmins,
  dataTeamMembers: [],
};
/**
 * Component to wrap the form component with create logic
 */
export function DatasetFormCreate({ backend, attrStmtBackend, user }: DatasetFormCreateProps) {
  const history: any = useHistory();

  useEffect(() => {
    (async () => {
      const usersResult = await backend.listUsers(user.email);
      user = usersResult.users[0]
      if (user) {
        INITIAL_VALUES.dataAdmins.push(user);
      }
    })();
  }, []);

  /**
   * Handles the submission of the dataset form by first making a request
   * to create the Dataset, get an ID back in response and then use
   * that ID to create DatasetMembers then creating the dataset config in
   * the attribute and statement generation service then redirecting to
   * the dataset table page.
   */
  const submitCreateDatasetForm = async (formData: FormikValues) => {
    const datasetConfig = formToDatasetConfig(formData, DEFAULT_DASG_CONFIG);
    const res = await backend.createDataset(formToDataset(formData));
    const datasetId = res.dataset.id;

    await backend.updateDatasetMembers(datasetId, {
      members: formToDatasetMembers(formData),
    });

    await attrStmtBackend.saveDatasetConfig(
      datasetId,
      formToDatasetConfig(formData, DEFAULT_DASG_CONFIG)
    );

    if (formData.refreshFrequency.value === 'NONE') {
      await attrStmtBackend.triggerHLLGeneration(datasetId);
    }

    history.push('/');
  };

  return (
    <DatasetForm
      backend={backend}
      attrStmtBackend={attrStmtBackend}
      onSubmit={submitCreateDatasetForm}
      initialValues={INITIAL_VALUES}
      initialTouched={{}}
    />
  );
}

/*
 * Only Datafeeds with a single model, with fieldMapping defined and with either no dateFields or
 * a single dateField element are customizable
 */
export function isCustomizable(config: DataFeedConfig) {
  const mapping: FieldMapping | undefined = config.models[0].fieldMapping;
  const hasOneModel = config.models.length === 1;
  const hasMapping = !!mapping;
  const dateFields = mapping ? mapping.dateFields : [];
  const hasMultipleDateFields = dateFields.length > 1;

  return hasOneModel && hasMapping && !hasMultipleDateFields;
}

/**
 * Component to wrap the form component with update logic
 */
export function DatasetFormUpdate({ backend, attrStmtBackend }: DatasetFormCreateProps) {
  const history: any = useHistory();
  const params: { [key: string]: string } = useParams();
  const datasetId = parseInt(params.id);
  const [initialDataset, setInitialDataset] = useState<Dataset>(DEFAULT_DATASET);
  const [datasetInitialValues, setDatasetInitialValues] = useState<{
    [key: string]: any;
  } | null>(null);
  const [refreshStartDate, setRefreshStartDate] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [originalConfig, setOriginalConfig] = useState(DEFAULT_DASG_CONFIG);
  const [isWarning, setWarning] = useState(false);

  /*
   * On close of NotEditableModal
   */
  const onCloseNotEditable = () => {
    setWarning(false);
    history.push('/');
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const dataset = await backend.getDataset(datasetId);
  
      if (!dataset) {
        // No dataset found
        setWarning(true);
        setIsLoading(false);
        return;
      }
  
      // Get the config from the backend
      const config = await attrStmtBackend.getDataFeedConfig(datasetId);
  
      // If no config object exists from DASG, use the default and allow edits
      if (!config || !config.models) {
        setInitialDataset(dataset.dataset);
        const updatedInitialValues = {
          ...INITIAL_VALUES,
          dataName: dataset.dataset.name,
          dataDescription: dataset.dataset.description,
          dataProvider: { id: dataset.dataset.data_provider, name: "" },
          dataTeamMembers: dataset.dataset.members?.filter((m) => !m.is_admin),
          dataAdmins: dataset.dataset.members?.filter((m) => m.is_admin),
          dataDestination: {
            value: "HVM",
            label: "HealthVerity marketplace (private data)",
          },
          dataType: {
            id: dataset.dataset.datatype,
            name: DATA_TYPES.find(({ id }) => id == dataset.dataset.datatype)
              ?.name,
          },
          refreshFrequency: { value: "NONE", label: "None" },
        };
  
        setDatasetInitialValues(updatedInitialValues);
        setInitialDataset(dataset.dataset);
  
        setWarning(false);
        setIsLoading(false);
  
        // If config exists in DASG, but is not customizable,
        // show errors
      } else if (config && !isCustomizable(config)) {
        setWarning(true);
        setIsLoading(false);
        return;
        // DASG config is good and editable
      } else {
        setOriginalConfig(config);
        setRefreshStartDate(config!.refreshConfig?.startDate);
        const formValues = datasetConfigToForm(config, dataset.dataset);
  
        setDatasetInitialValues(formValues);
        setInitialDataset(dataset.dataset);
        setIsLoading(false);
      }
    })();
  }, []);
  

  const onSubmit = async (formData: FormikValues) => {
    const formToDatasetData = formToDataset(formData, initialDataset);
    const update_resp = await backend.updateDataset(datasetId, formToDatasetData);
    const update_members_resp = await backend.updateDatasetMembers(datasetId, {
      members: formToDatasetMembers(formData),
    });

    // If a refresh frequency was configured make sure start date is included
    // If not make sure it does not override existing start date
    const datasetConfig = formToDatasetConfig(formData, originalConfig);
    if (
      refreshStartDate &&
      formData.refreshFrequency.value !== 'NONE' &&
      datasetConfig.refreshConfig
    ) {
      datasetConfig.refreshConfig.startDate = refreshStartDate;
    }

    const update_config_resp = await attrStmtBackend.saveDatasetConfig(datasetId, datasetConfig);

    // Conditions on which to trigger an HLL generation request
    const trigger_hll_conditions = [
      !isEqual(datasetInitialValues!.fieldMapping, formData.fieldMapping),
      datasetInitialValues!.indexedFields != formData.indexedFields,
      datasetInitialValues!.dataSourceHiveConditions != formData.dataSourceHiveConditions,
      datasetInitialValues!.dataSourceHive != formData.dataSourceHive,
      datasetInitialValues!.dataSourceS3 != formData.dataSourceS3,
    ];
    if (trigger_hll_conditions.includes(true)) {
      await attrStmtBackend.triggerHLLGeneration(datasetId);
    }
    history.push('/');
  };
  if (isLoading) {
    return <div className="loading" />;
  }
  return (
    <>
      {isWarning ? (
        <NotEditableModal close={onCloseNotEditable} />
      ) : (
        <DatasetForm
          backend={backend}
          attrStmtBackend={attrStmtBackend}
          onSubmit={onSubmit}
          initialValues={datasetInitialValues}
          initialTouched={{
            dataSourceType: true,
            dataSourceS3Delimiter: true,
            dataSourceS3:
              datasetInitialValues?.dataSourceType?.value == SOURCE_TYPE_S3 ? true : false,
            dataSourceHive:
              datasetInitialValues?.dataSourceType?.value == SOURCE_TYPE_HIVE ? true : false,
            dataTeamMembers: true,
            dataProvider: true,
          }}
          initalPage={4}
        />
      )}
    </>
  );
}

type DatasetFormProps = {
  backend: Backend;
  attrStmtBackend: AttributeStatementBackend;
  onSubmit: (formData: FormikValues) => Promise<void>;
  initialValues: { [key: string]: any } | null;
  initialTouched: { [key: string]: any };
  initalPage?: number;
};

/**
 * Component to handle the dataset form presentation and logic
 */
function DatasetForm({
  backend,
  attrStmtBackend,
  onSubmit,
  initialValues,
  initialTouched,
  initalPage,
}: DatasetFormProps) {
  const history: any = useHistory();
  const [activePage, setActivePage]: [number, (activePage: number) => void] = useState(
    initalPage || 1
  );
  const [showIdentifiableInfoModal, setShowIdentifiableInfoModal] = useState(true);
  const [dataProviders, setDataProviders] = useState<DataProvider[]>([]);
  const [initialFormValues, setInitialFormValues] = useState<{
    [key: string]: any;
  } | null>(initialValues);
  const [shouldShowAutoIndexModal, setShouldShowAutoIndexModal] = useState(false);
  const [scannedFieldMappingResult, setScannedFieldMappingResult] =
    useState<ScannedFieldMappingResultProps>(scannedFieldMappingEmptyResult);
  const [validationIsLoading, setValidationIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (initialValues) {
        const results = await backend.listDataProviders();
        setDataProviders(results.data_providers);

        // On an Update when this component gets unmounted dataProvider type gets
        // changed, make sure to check for that
        if (initialValues.dataProvider?.id) {
          const dataProvider = results.data_providers.find(
            (p) => p.id == initialValues.dataProvider.id
          );
          setInitialFormValues({ ...initialValues, dataProvider });
        }
      }
    })();
  }, [initialValues]);

  async function validateDataSource(sourceType: string, value: string) {
    let response = null;
    setValidationIsLoading(true);
    if (sourceType === SOURCE_TYPE_S3) {
      response = await attrStmtBackend.s3PathExists(value);
    } else if (sourceType === SOURCE_TYPE_HIVE) {
      response = await attrStmtBackend.tableExists(value);
    }
    setValidationIsLoading(false);

    return response && response['exists'];
  }

  return (
    <>
      {initialFormValues && (
        <div className="add-dataset-container">
          {shouldShowAutoIndexModal && <AutoscanningDataModal />}
          <div className="add-data-container-header">Add new data</div>
          <div className="add-data-progress-section">
            <div className="add-data-line" />
            <ProgressBox
              number={1}
              isActive={activePage === 1}
              isComplete={activePage > 1}
              title={'Configure data flow'}
            />
            <ProgressBox
              number={2}
              isActive={activePage === 2}
              isComplete={activePage > 2}
              title={'Provide data information'}
            />
            <ProgressBox
              number={3}
              isActive={activePage === 3}
              isComplete={activePage > 3}
              title={'Select data permissions'}
            />
            <ProgressBox
              number={4}
              isActive={activePage === 4}
              isComplete={activePage > 4}
              title={'Review and submit'}
            />
          </div>
          <Formik
            initialValues={initialFormValues}
            initialTouched={initialTouched}
            enableReinitialize
            onSubmit={async (values) => {
              await onSubmit(values);
            }}
            validate={async (values) => {
              const errors: any = {};
              // If S3, validate dataSourceS3 against S3_PATH_VALIDATION_REGEX and that the path exists
              if (values.dataSourceType.value === SOURCE_TYPE_S3) {
                if (!values.dataSourceS3.match(S3_PATH_VALIDATION_REGEX)) {
                  errors.dataSourceS3 = 'Invalid file name';
                } else if (
                  !(await validateDataSource(values.dataSourceType.value, values.dataSourceS3))
                ) {
                  errors.dataSourceS3 =
                    'This does not appear to be a valid directory, please check again.';
                }

                // Make sure delimiter is supplied
                const isDelimitedType =
                  values.dataSourceS3FileFormat.value === FileFormat.DSV_QUOTE ||
                  values.dataSourceS3FileFormat.value === FileFormat.DSV_NOQUOTE;
                if (
                  isDelimitedType &&
                  (!values.dataSourceS3Delimiter || !values.dataSourceS3Delimiter.length)
                ) {
                  errors.dataSourceS3Delimiter = 'Please enter a valid delimiter.';
                }
              }
              // If HIVE, validate dataSourceHive against HIVE_VALIDATION_REGEX and that table exists
              else if (values.dataSourceType.value === SOURCE_TYPE_HIVE) {
                if (!values.dataSourceHive.match(HIVE_VALIDATION_REGEX)) {
                  errors.dataSourceHive = 'Invalid Hive table';
                } else if (
                  !(await validateDataSource(values.dataSourceType.value, values.dataSourceHive))
                ) {
                  errors.dataSourceHive = 'Table name not recognized, please check again.';
                }
              }
              // If HIVE, validate partition that both key/value are provided & that all keys are unique
              if (
                values.dataSourceType.value == SOURCE_TYPE_HIVE &&
                values.dataSourceHiveConditions
              ) {
                const addPartitionErrorAtIndex = (index: number, error: string) => {
                  if (!errors.dataSourceHiveConditions) errors.dataSourceHiveConditions = [];
                  errors.dataSourceHiveConditions[index] = {
                    conditionKey: error,
                  };
                };
                let uniqueConditionKeys = new Array<string>();
                values.dataSourceHiveConditions.forEach(
                  ({ conditionKey, conditionValue }: HiveCondition, index: number) => {
                    if ((conditionKey || conditionValue) && (!conditionKey || !conditionValue)) {
                      addPartitionErrorAtIndex(
                        index,
                        'Please supply both a filter field and filter value.'
                      );
                    } else if (conditionKey && uniqueConditionKeys.indexOf(conditionKey) >= 0) {
                      addPartitionErrorAtIndex(index, 'Please make sure filter fields are unique.');
                    } else if (conditionKey) {
                      uniqueConditionKeys.push(conditionKey);
                    }
                  }
                );
              }
              // Admins cannot be Data Team Members
              if (values.dataAdmins && values.dataTeamMembers) {
                values.dataTeamMembers.forEach((member: User) => {
                  const isAdmin = values.dataAdmins.find(
                    (admin: User) => admin.user_id === member.user_id
                  );
                  if (isAdmin) {
                    errors.dataTeamMembers = 'Admin cannot be a team member';
                  }
                });
              }
              if (values.dataAdmins.length === 0) {
                errors.dataAdmins = 'At least one admin must be added';
              }
              return errors;
            }}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setSubmitting,
              setFieldTouched,
              setFieldValue,
              validateForm,
              isValid,
            }) => {
              const scanDataSourceAndPerformMapping = async (values: FormikValues) => {
                setShouldShowAutoIndexModal(true);
                const dataSourceLocation =
                  values.dataSourceType.value === SOURCE_TYPE_S3
                    ? values.dataSourceS3
                    : values.dataSourceHive;
                try {
                  const scannedSourceFieldNames = await attrStmtBackend.getDatasetSourceFields(
                    values.dataSourceType.value,
                    dataSourceLocation,
                    values.dataSourceS3FileFormat.value,
                    values.dataSourceS3Delimiter
                  );
                  const scannedFieldMappingResult = performFieldMappingOnScannedFields(
                    scannedSourceFieldNames.fields,
                    values.fieldMapping
                  );
                  setScannedFieldMappingResult(scannedFieldMappingResult);
                  setFieldValue(
                    'fieldMapping',
                    scannedFieldMappingResult.valuePreservingAutoMappedFields
                  );
                  setFieldValue('indexedFields', scannedFieldMappingResult.autoMappedIndexedFields);
                } catch (error) {
                  // Note: if failure accessing fields, the ProvideDataInformation step will indicate issue to user
                  console.log(`Error scanning data source ${dataSourceLocation}: ${error}`);
                  setScannedFieldMappingResult(scannedFieldMappingEmptyResult);
                  setFieldValue(
                    'fieldMapping',
                    scannedFieldMappingEmptyResult.valuePreservingAutoMappedFields
                  );
                  setFieldValue(
                    'indexedFields',
                    scannedFieldMappingEmptyResult.autoMappedIndexedFields
                  );
                }
                setShouldShowAutoIndexModal(false);
              };

              const onEditFromSummaryPage = async (event: React.MouseEvent, field: string) => {
                event.stopPropagation();
                const updateSourceDataFields = async () => {
                  await scanDataSourceAndPerformMapping(values);
                };
                await updateSourceDataFields();
                if (CONFIGURE_DATA_FLOW_FORM_FIELDS.includes(field)) {
                  setActivePage(1);
                } else if (PROVIDE_DATA_INFORMATION_FORM_FIELDS.includes(field)) {
                  setActivePage(2);
                } else if (SELECT_DATA_PERMISSIONS_FORM_FIELDS.includes(field)) {
                  setActivePage(3);
                } else {
                  console.error(`Field ${field} is not mapped to a form page`);
                  setActivePage(1);
                }
              };

              return (
                <form id={'search'}>
                  <div>
                    {activePage === 1 && (
                      <ConfigureDataFlow
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={(name: string, value: any) => {
                          // For fields such as dropdowns, event object is built using the value
                          // For text input fields, value itself is the event object
                          let event = value.target ? value : { target: { name, value } };
                          handleChange(event);
                        }}
                        onNext={() => {
                          setActivePage(2);
                        }}
                        scanDataSourceAndPerformMapping={scanDataSourceAndPerformMapping}
                        validationIsLoading={validationIsLoading}
                      />
                    )}
                    {activePage === 2 && (
                      <ProvideDataInformation
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={(name: string, value: any) => {
                          // For fields such as dropdowns, event object is built using the value
                          // For text input fields, value itself is the event object
                          let event = value.target ? value : { target: { name, value } };
                          handleChange(event);
                        }}
                        onBack={() => setActivePage(1)}
                        onNext={() => setActivePage(3)}
                        setFieldValue={setFieldValue}
                        dataProviders={dataProviders}
                        scannedFieldMappingResult={scannedFieldMappingResult}
                      />
                    )}
                    {activePage === 3 && (
                      <SelectDataPermission
                        backend={backend}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={(
                          fieldName: string,
                          isTouched?: boolean,
                          shouldValidate?: boolean
                        ) => {
                          setFieldTouched(fieldName, isTouched, shouldValidate);
                        }}
                        handleChange={(name: string, value: any) => {
                          // For fields such as dropdowns, event object is built using the value
                          // For text input fields, value itself is the event object
                          let event = value.target ? value : { target: { name, value } };
                          handleChange(event);
                        }}
                        onBack={() => {
                          setActivePage(2);
                        }}
                        onNext={() => setActivePage(4)}
                      />
                    )}
                    {activePage === 4 && (
                      <NewDataSummary
                        values={values}
                        errors={errors}
                        onBack={() => setActivePage(3)}
                        onNext={async () => {
                          setSubmitting(true);
                          const formErrors = await validateForm();
                          if (Object.keys(formErrors).length === 0) {
                            await handleSubmit();
                            setActivePage(5);
                          }
                          setSubmitting(false);
                        }}
                        isSubmitting={isSubmitting}
                        onEdit={onEditFromSummaryPage}
                      />
                    )}
                    {activePage === 5 && <ProcessingNotification />}
                  </div>
                </form>
              );
            }}
          </Formik>

          {showIdentifiableInfoModal && (
            <IdentifiableInfoModal
              onCancel={() => {
                setShowIdentifiableInfoModal(false);
                history.push('/');
              }}
              onContinue={() => setShowIdentifiableInfoModal(false)}
            />
          )}
        </div>
      )}
    </>
  );
}

type ProgressBoxProps = {
  number: number;
  isActive: boolean;
  isComplete: boolean;
  title: string;
};

/**
 * Generic Component to render the progress box
 * on top of the Add New Data page
 */
function ProgressBox({ number, isActive, isComplete, title }: ProgressBoxProps) {
  let fillClass = '';
  if (isActive) {
    fillClass = 'box-active';
  } else if (isComplete) {
    fillClass = 'box-complete';
  }
  return (
    <div className="add-data-progress-box-container">
      <div className={'add-data-progress-box ' + fillClass}>
        {isComplete && <img src={checkmark} />}
        {!isComplete && number}
      </div>
      {title}
    </div>
  );
}
